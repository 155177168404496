<template>
  <router-view :roles="roles"></router-view>
</template>

<style>
  @media screen {
    main.v-content {
      max-width:1920px;
    }
  }
</style>

<script>
export default {
  name: 'Reports',
  props: ['roles']
}
</script>